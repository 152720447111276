/** 
 * page header
 * author meric
 * 2022-07-01
 */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from './fragment/Logo';

import { useTranslation } from 'react-i18next';



const propTypes = {
  isDefaultLogo:PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  isDefaultLogo:false,
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}


const Header =({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {

  const { t ,i18n} = useTranslation();
  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });  

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }
/**
 * 
 * @param {lng} e 
 * 多语言切换
 */
  const changLang=(e)=>{
    let lng=e.target.getAttribute("lang");
    if(lng ==="" || ["en","zh"].indexOf(lng)===-1){
      lng="en";
    }
    i18n.changeLanguage(lng);
    closeMenu();
  }
  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }  

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );

  return (
    <header
      {...props}
      className={classes}
    >
      <div className="container">
        <div className={
          classNames(
            'site-header-inner',
            bottomDivider && 'has-bottom-divider'
          )}>
          <Logo  {...props}  />
          {!hideNav &&
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={
                  classNames(
                    'header-nav',
                    isActive && 'is-active'
                  )}>
                <div className="header-nav-inner">
                  <ul className={
                    classNames(
                      'list-reset text-xs',
                      navPosition && `header-nav-${navPosition}`
                    )}>
                     
                    <li>

                      <a href="https://www.redgecko.cn/mall/"  onClick={closeMenu}>{t("header.mall")}</a>
                   
                    </li>
                    
                    <li>
                      <Link to="/web/supplier" onClick={closeMenu}>{t('header.supplier')}</Link>
                    </li>
                    
                  </ul>
                  {!hideSignin &&
                    <ul
                      className="list-reset text-xs header-nav-right"
                    >
                      <li>
                        <Link to="/web/tender" onClick={closeMenu}>{t('header.solutions')}</Link>
                      </li>
                      <li>
                      <Link to="/web/about" onClick={closeMenu}>{t('header.abouts')}</Link>
                    </li>
                      <li>
                        <span className='chang-lang'><a className='btn-lang-left' lang='en' onClick={changLang}>English</a>|<a className="btn-lang-right" lang='zh' onClick={changLang}>中文</a></span>
                      </li>
                      
                    </ul>}
                </div>
              </nav>
            </>}
        </div>
      </div>
    </header>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
