import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../../components/elements/ButtonGroup';
import Image from '../../components/elements/Image';
import Modal from '../../components/elements/Modal';
import { Link } from 'react-router-dom';
import { PlayCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Blueprint = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);
  const { t } = useTranslation();
  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="100">
              <span className="text-color-primary"> {t('index.bp.primary')}</span> {t('index.bp.tail')}
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="200">
                {t('index.bp.bpTxt')}
                <br />
                {t('index.bp.tar')}
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="300">
                <ButtonGroup>
                  <Link className='button button-primary' to="/web/quotations">
                  {t('index.bp.btnRQ')}
                  </Link>
                  <Link className='button button-dark' to="/web/tender">
                  {t('index.bp.btnPc')}
                  </Link>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="400">
            <a
              data-video="/videos/1658374421062692.mp4"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src="/images/v-p-1463.png"
                srcset="/images/v-p-488.png 320w,
                        /images/v-p-644.png 640w,
                        /images/v-p-975.png 960w"
                sizes="896px"
                alt="video"
                width={896}
                height={504} />
              <PlayCircleOutlined className="btn-play" />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="/videos/1658374421062692.mp4"
            videoTag="iframe" />
        </div>
      </div>
    </section>
  );
}

Blueprint.propTypes = propTypes;
Blueprint.defaultProps = defaultProps;

export default Blueprint;