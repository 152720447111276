import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from '../../elements/Image';

const propTypes = {
    data: PropTypes.shape({
        slogan: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
        imgSrc: PropTypes.string
    }).isRequired,
    children: PropTypes.node
}

const SplitItem = ({
    className,
    data,
    children,
    ...props
}) => {
    //"split-item-content center-content-mobile reveal-from-left"
    const direction = classNames(
        'split-item-content center-content-mobile',
        className
    );
    return (
        <>
            {(data.title || data.content) &&
                <div className="split-item">
                    <div className={direction} data-reveal-container=".split-item">
                        <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                            {data.slogan}
                        </div>
                        <h3 className="mt-0 mb-12">
                            {data.title}
                        </h3>
                        <p className="m-0">
                            {data.content}
                        </p>
                    </div>
                    <div className='split-item-image center-content-mobile reveal-from-bottom split-item-image-fill'
                        data-reveal-container=".split-item">
                        <Image
                            src={data.imgSrc}
                            alt="red gecko image"
                            width={528}
                            height={396} />
                    </div>
                </div>
            }
        </>

    );
}

SplitItem.propTypes = propTypes;

export default SplitItem;