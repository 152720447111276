import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

import Image from '../../components/elements/Image';

import { useTranslation } from 'react-i18next';

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Culture = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const { t } = useTranslation();

    const outerClasses = classNames(
        'section about',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );


    return (
        <section
            {...props}
            className={outerClasses}>
            <div className="container">
                <div className="features-tiles-inner section-inner pt-12 center-content">
                    <h3 class="mt-16 mb-16">{t("about.culture.title")}</h3>
                    <div >
                           {t("about.culture.summary")} 
                        </div>
                    <div className='tiles-wrap'>
                        <div class="tiles-item">
                            <div class="tiles-item-inner">
                                <div class="features-tiles-item-header mb-24">
                                <Image className="img-icon" src="/images/mission-320x200.png" width={220} alt="culture image" />
                                </div>
                                <div class="features-tiles-item-content">
                                    <h4 class="mt-0 mb-8"> {t("about.culture.mission.title")} </h4>
                                    <p class="m-0 text-sm"> {t("about.culture.mission.content")} </p>
                                </div>
                            </div>
                        </div>
                        <div class="tiles-item">
                            <div class="tiles-item-inner">
                                <div class="features-tiles-item-header mb-24">
                                <Image className="img-icon" src="/images/vission-320x200.jpg" width={220} alt="culture image" />
                                </div>
                                <div class="features-tiles-item-content">
                                    <h4 class="mt-0 mb-8">{t("about.culture.vission.title")}</h4>
                                    <p class="m-0 text-sm">{t("about.culture.vission.c1")}</p>
                                    <p class="m-0 text-sm">{t("about.culture.vission.c2")}</p>
                                </div>
                            </div>
                        </div>
                        <div class="tiles-item">
                            <div class="tiles-item-inner">
                                <div class="features-tiles-item-header mb-24">
                                <Image className="img-icon" src="/images/jzg-320.png" width={220} alt="culture image" />
                                </div>
                                <div class="features-tiles-item-content">
                                    <h4 class="mt-0 mb-8">{t("about.culture.value.title")}</h4>
                                    <p class="m-0 text-sm">{t("about.culture.value.content")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

Culture.propTypes = propTypes;
Culture.defaultProps = defaultProps;

export default Culture;