import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../elements/Image';
const propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        paragraph: PropTypes.string
    }).isRequired,
    children: PropTypes.node
}

const SectionBlock = ({
    className,
    data,
    children,
    tag,
    ...props
}) => {

    return (
        <>
            {(data.title || data.paragraph) &&
                <div className="tiles-item reveal-from-bottom"
                    {...props}
                >
                    <div className="tiles-item-inner">
                        <div className="features-tiles-item-header">
                            <div className="features-tiles-item-image mb-16">
                                {
                                    data.icon&&
                                    <data.icon style={{ "width": 64, "height": 64, "color": "#fff" }} />
                                
                                    ||
                                    data.image &&
                                    <Image src={data.image}  alt="red gecko icon"
                                    width={64}
                                    height={64} 
                                     />
                                }


                            </div>
                        </div>
                        <div className="features-tiles-item-content">
                            <h4 className="mt-0 mb-8">
                                {data.title}
                            </h4>
                            <p className="m-0 text-sm">
                                {data.paragraph}
                            </p>
                        </div>
                    </div>
                </div>
            }
        </>

    );
}

SectionBlock.propTypes = propTypes;


export default SectionBlock;